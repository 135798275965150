<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>COIN IN</sub-title>

                <div class="main_panel">
                    <div class="re_info">
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i> {{i18nConst.COIN['COIN_IN_MSG_1'][$store.state.country]}}</strong>
                        </span>
                    </div>

                    <div class="recharge_cont">

                        <div class="cont_1">
                            <h4>{{i18nConst.USER['COIN_NAME'][$store.state.country]}}</h4>
                            <select name="" id="" style="width: 80%;">
                                <option value="ETH">ETH</option>
                            </select>
                            <h4>{{i18nConst.USER['USER_COIN'][$store.state.country]}}</h4>
                            <p style="">{{$store.state.ethBalance}} ETH <span style="padding: 0 5px;cursor: pointer"
                                                                              @click="refreshCoin">
                                <i class="fa fa-refresh"></i>
                            </span></p>

                            <h4>{{i18nConst.COIN['COIN_RATE'][$store.state.country]}}</h4>
                            <p>{{$store.state.ethPrice}} $</p>

                            <h4>{{i18nConst.COIN['CAN_CHANGE_COIN'][$store.state.country]}}</h4>
                            <p>{{$store.state.ethPrice * $store.state.ethBalance}} $</p>
                        </div>
                        <div class="cont_2">
                            <div class="c1">
                                <h4> ETH {{i18nConst.COIN['COIN_WALLET_ADDRESS'][$store.state.country]}}</h4>
                                <h4>{{$store.state.ethWallet}}</h4>
                            </div>
                            <div class="c2">
                                <div>
                                    <h4>입금주소 보안 확인</h4>
                                    <div style="width: 100%;text-align: right;line-height: 32px;cursor: pointer" @click="copyWallet">
                                        <i class="fa fa-copy"></i>
                                    </div>
                                </div>
                                <div>
                                    <img :src="QRCodeImgUrl" style="width: 100px; height: 100px">
                                </div>
                            </div>
                            <div class="c3">
                                <div class="re_cont">
                                    <div class="c22" style="">
                                        <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">
                                            <input type="number" v-model="applyCash.amount" class="re_input"
                                                   placeholder="amount"
                                                   style="">
                                            <button type="button" class="btn-re" @click="changeMoney(0)"
                                                    style="width: 130px;margin-left: 6px">
                                                {{i18nConst.BET['RESET'][$store.state.country]}}
                                            </button>
                                        </div>
                                        <div style="display: flex; justify-content: flex-start;width: 100%;padding: 10px 0">
                                            <button type="button" class="btn-re" @click="changeMoney(10)">
                                                10$
                                            </button>
                                            <button type="button" class="btn-re" @click="changeMoney(50)">
                                                50$
                                            </button>
                                            <button type="button" class="btn-re" @click="changeMoney(100)">
                                                100$
                                            </button>
                                            <button type="button" class="btn-re" @click="changeMoney(200)">
                                                200$
                                            </button>
                                            <button type="button" class="btn-re" @click="changeMoney(500)">
                                                500$
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="rec_buttons" style="margin-top: 5px">
                        <button class="b1" @click="recharge">{{i18nConst.COIN['COIN_CONVERSION'][$store.state.country]}}
                        </button>
                    </div>
                </div>
                <div class="re_history">
                    <table cellpadding="0" cellspacing="0" border="0">
                        <colgroup>
                            <col width="27%">
                            <col width="27%">
                            <col width="27%">
                            <col width="15%">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>{{i18nConst.COMMON['DATE'][$store.state.country]}}</th>
                            <th>{{i18nConst.COMMON['AMOUNT'][$store.state.country]}}</th>
                            <th @click="refreshData()" style="cursor: pointer">
                                {{i18nConst.COMMON['STATUS'][$store.state.country]}} <i class="fa fa-refresh"
                                                                                        aria-hidden="true"></i>
                            </th>
                            <th>-</th>
                        </tr>
                        <tr v-for="(item,indx) in rechargeList" :key="indx">
                            <td>{{item.createTime|datef('MM/DD HH:mm')}}</td>
                            <td>{{item.amount|comma}}</td>
                            <td>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">{{i18nConst.COMMON['UNIDENTIFIED'][$store.state.country]}}</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">{{i18nConst.COMMON['CHECKING'][$store.state.country]}}</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE"><i
                                        class="fa fa-check"
                                        aria-hidden="true"></i></span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                                      style="color: red">{{i18nConst.COMMON['FAILURE'][$store.state.country]}}</span>
                            </td>
                            <td>
                  <span
                          style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer"
                          v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> {{i18nConst.COMMON['DELETE'][$store.state.country]}}
                  </span>

                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>

</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {deleteApply, getApplyCashList, rechargeCash, saveQuestion} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {addMemberCasinoCash, getAccountBalance} from "../../network/casinoRequest";
    import {
        RECEIVE_HIDE_LOADING,
        RECEIVE_SHOW_LOADING,
        RECEIVE_SPORTS_CART_ITEM_DEL_ALL
    } from "../../store/mutation-types";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {coinBalance, coinPrice} from "../../network/coinRequest";
    import i18nConst from "../../common/i18nConst";
    import QRcode from "qrcode";

    export default {
        name: "Recharge",
        mixins: [postionMixin],
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp, TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                applyCash: {
                    amount: 0,
                    bonusin: 1,
                    cointype: sportsConst.ETH
                },
                search: {applyType: sportsConst.APPLY_TYPE_RECHARGE},
                rechargeList: [],
                isSampleSite: false,
                casinocash: 0,
                position: "입금",
                i18nConst: i18nConst,
                QRCodeImgUrl: ''
            }
        },
        methods: {
            refreshCoin() {
                this.$store.dispatch('actionCoinPriceAndBalance')
                this.$store.dispatch('actionUserInfo')
            },
            changeMoney(cash) {
                if (cash === 0) {
                    this.applyCash.amount = 0;
                } else {
                    this.applyCash.amount += cash;
                }
            },
            changeCasinoMoney(cash) {
                if (cash === 0) {
                    this.casinocash = 0;
                } else {
                    this.casinocash += parseInt(cash);
                }
            },
            recharge() {
                if (this.applyCash.bonusin === -1) {
                    this.$swal({
                        title: i18nConst.COIN['ACCEPT_BONUS'][this.$store.state.country],
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false
                }


                this.$swal({
                    //title: this.applyCash.amount + '$ 전환신청 하시겠습니까?',
                    title: this.applyCash.amount + i18nConst.COIN["COIN_CONVERSION_MSG_1"][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: ' YES ',
                    cancelButtonText: 'NOT'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        rechargeCash(this.applyCash).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    title: i18nConst.COIN["REQUST_COMPLETED"][this.$store.state.country],
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.applyCash.bonusin = 1
                                this.applyCash.amount = 0
                                this.initRechargeList()
                                this.refreshCoin();
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                        })
                    }
                })
            },
            change2casinocash() {
                if (this.casinocash > this.$store.state.userInfo.cash) {
                    this.$swal({
                        title: '보유금액 ' + this.$store.state.userInfo.cash + '$를 초과하였습니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                addMemberCasinoCash(this.casinocash).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$swal({
                            title: i18nConst.COIN["CONVERSION_SUCCESS"][this.$store.state.country],
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.casinocash = 0;
                        this.$store.dispatch('actionUserInfo')
                        setTimeout(() => {
                            this.getCasinoBalance();
                        }, 1300)

                    } else {
                        this.$swal({
                            title: i18nConst.COIN["CONVERSION_FAILED"][this.$store.state.country] + res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })
            },
            initRechargeList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getApplyCashList(this.search).then(res => {
                    if (res.data.success) {
                        this.rechargeList = res.data.data
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            refreshData() {
                this.initRechargeList()
                this.$bus.$emit('applyRequestSuccess')
            },
            deleteById(id) {
                this.$swal({
                    title: i18nConst.COMMON["CAN_DELETE_MSG_1"][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: ' ' + i18nConst.COMMON["YES"][this.$store.state.country] + ' ',
                    cancelButtonText: i18nConst.COMMON["NO"][this.$store.state.country]
                }).then((res) => {
                    if (res.value) {
                        deleteApply(id).then(res => {
                            if (res.data.success) {
                                this.initRechargeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            getCasinoBalance() {
                getAccountBalance().then(res => {
                    if (res.data.data) {
                        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                    } else {
                        this.$set(this.$store.state.userInfo, 'casinocash', 0)
                    }

                })
            },
            questioAcNo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                let content = '<p>계좌번호 문의합니다</p>'
                saveQuestion({'content': content, 'type': sportsConst.CUSTOMER_TYPE_BANK_CARD}).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '계좌번호 문의글 작성이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.$router.push('/customer_center')

                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            getQRcode() {
                QRcode.toDataURL(this.$store.state.ethWallet, {
                    errorCorrectionLevel: 'L',
                    margin: 0,
                    width: 100
                }, (err, url) => {
                    if (err) throw err
                    this.QRCodeImgUrl = url
                })
            },
            copyWallet(){
                let transfer = document.createElement('input');//创建控件
                document.body.appendChild(transfer);
                transfer.style.cssText = 'position: absolute;right: 45%;top: 80%;'
                transfer.value = this.$store.state.ethWallet;  // 这里表示想要复制的内容
                transfer.focus();
                transfer.select();
                if (document.execCommand('copy')) {
                    document.execCommand('copy');
                }
                transfer.blur();
                // console.log('复制成功');
                document.body.removeChild(transfer);//删除控件
                this.$swal({
                    title: i18nConst.COIN['COPY_WALLET'][this.$store.state.country],
                    type: 'success',
                    showCancelButton: false,
                    showConfirmButton: true
                })

            }
        },
        created() {
            this.getCasinoBalance();
            this.initRechargeList();
            this.$store.dispatch('actionCoinPriceAndBalance')
            this.getQRcode();
        },
        mounted() {

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/reex.css");
</style>